<template>
	<el-dialog title="管理客户分组" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="35%">

		<div v-loading="loading">
			<el-button @click="editHandle">新建分组</el-button>
			<el-table :data="datalist_enterprise" height="210px" border class="myClient-top-20">
				<el-table-column property="GroupName" label="分组名" align="center"></el-table-column>
				<el-table-column property="UserCount" label="客户数" align="center"></el-table-column>
				<el-table-column property="primary" label="操作" align="center">
					<template slot-scope="{row,$index}">
						<div class="flex justify-content-around">
							<span class="text-blue cursor" @click="Edit(row)">编辑</span>
							<span class="text-red cursor" @click="detail(row.id,$index)">删除</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="myClient-text flex justify-content-between myClient-top-10">
				<div>
					共计{{popleTotal}}个客户，{{total}}个分组
				</div>
			</div>
		</div>
		<addGroup :Visible="editVisible" @closepop="closeEditPop" :message="gropMessage"></addGroup>
	</el-dialog>
</template>

<script>
	import addGroup from '@/components/add-group/addGroup.vue'
	export default {
		components: {
			addGroup
		},
		data() {
			return {
				dialogVisible: true,
				popleTotal: 0,
				loading:true,
				total:0,
				editVisible: false,
				datalist_enterprise: [],
				gropMessage: {},
				listQuery: {
					PageIndex: 1,
					PageSize: 20,
					Sort: [{
						"Field": "id",
						"Type": 0
					}]
				}
			}
		},
		
		created() {
			this.SelectMyGroup()
		},
		methods: {
			close() {
				this.$emit('closepop')
			},
			closeEditPop(){
				this.editVisible = false;
				this.SelectMyGroup()
			},
			SelectMyGroup() {
				this.API.customerSelectMyGroup(this.listQuery).then(res => {
					this.datalist_enterprise = res.data.rows
					res.data.rows.map(item=>{
						this.popleTotal=this.popleTotal+item.UserCount
					})
					this.loading=false
					this.total=res.data.total
				})
				this.$forceUpdate()
			},
			Edit(row) {
				this.gropMessage = row
				this.editVisible = true
			},
			detail(id, index) {
				this.$confirm('此操作将永久删除该分组, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.datalist_enterprise.splice(index, 1)
					this.API.customerDeleteGroup(id).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		}
	}
</script>

<style>
</style>
