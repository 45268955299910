<template>
	<el-dialog :title="title" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="25%">
		<div> 
			<el-upload ref="importcustomer" class="upload-demo" style="padding:40px 0px;text-align:center" drag
			:action="action"
			:headers="{Authorization:token}"
				:on-success="up_success">
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				<div class="el-upload__tip" slot="tip">只能上传excel文件,单次最多上传1000个信息 点击 <a style="color:#2FA4E7"
						target="_blank" :href="baseURL+'/temp/客户导入模板.xlsx'">下载导入模板</a>
 
				</div>
			</el-upload>
		</div>
	</el-dialog>
</template>

<script>
		import {
		getToken
	} from '@/utils/auth'
	
	import baseURL from '@/utils/config'
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},	
			action: {
				type: String,
				default: '',
			},	title: {
				type: String,
				default: '',
			},
			message: {
				type: Object,
				default:()=>{
					return {}
				},
			}
		},
		data() {
			return {
				token: getToken(),
				baseURL,
				dialogVisible: false
			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;  
			},
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			up_success(data) { 
				if (data.success) {
					this.$alert('导入完成 '+data.message, '导入提示', {
						confirmButtonText: '确定',
						callback: action => {
							 
						}
					});
				}
				else {
					this.$message.error("导入失败")

				}
			},
			close() {
				this.$refs.importcustomer.clearFiles();
				this.$emit('closepop')
			} 
		}
	}
</script>

<style>
</style>
