<template>
	<el-dialog title="创建分组" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="25%">
		<div>
			<el-input placeholder="分组名10个字以内" v-model="groupMessage.GroupName "></el-input>
		
			<div class="flex justify-content-flexEnd myClient-top-10">
				<el-button type="primary" @click="submit">确定</el-button>
				<el-button class="myClient-left-30"  @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},
			message:{
				type: Object,
				default: ()=>{
					return {}
				},
			}
		},
		data() {
			return {
				dialogVisible: false,
				groupMessage:{
					GroupName: ''
				}
			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
			},
			message(val){
				if(val){
					this.groupMessage=val
				}
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop')
			},
			submit(){
				if(!this.groupMessage.GroupName){
					this.$message.error("请填写分组的名称")
				}else{
					this.API.customerEditGroup(this.groupMessage).then(res=>{
						this.$message.success("操作分组成功")
						this.groupMessage={}
						this.close()
					})
				}
			}
		}
	}
</script>

<style>
</style>
