<template>
	<el-dialog :title="title" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="25%">
		<div>
			<el-input placeholder="新建文件夹" v-model="FilepMessage.FolderName "></el-input>
			<div class="flex justify-content-flexEnd margin-top">
				<el-button type="primary" @click="AddEditFolde" v-preventReClick="500">确定</el-button>
				<el-button class="myClient-left-30" @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},
			message: {
				type: Object,
				default:()=>{
					return {}
				},
			}
		},
		data() {
			return {
				dialogVisible: false,
				title:'',
				FilepMessage: {
					CustomerId: 0,
					FolderName: '',
				}
			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
				this.title=this.message.title
				if(this.message.uerid>0){
					this.FilepMessage.CustomerId = this.message.uerid
				}
				else{
					this.FilepMessage.CustomerId=this.message.detail.CustomerId
					this.FilepMessage.FolderName =this.message.detail.FolderName 
					this.FilepMessage.id=this.message.detail.id
					console.log(this.FilepMessage)
				}
			},
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.FilepMessage={
					CustomerId: 0,
					FolderName: '',
				}
				this.$emit('closepop')
			},
			AddEditFolde() { 
				this.API.customerEditFolde(this.FilepMessage).then(res => {
					if (res.code == 200) {
						this.FilepMessage={}
						this.$message.success("文件夹操作成功")
						this.close()
					}
				})
			}
		}
	}
</script>

<style>
</style>
