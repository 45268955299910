<template>
	<el-dialog title="客户资料" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="780px">
		<div style="height: 650px; overflow-y: auto" v-loading="loadings">
			<div class="myClient_client_header clear">
				<el-col :span="12">
					<div class="flex justify-content-between padding-top-sm">
						<div class="flex justify-content-between" style="width: 100%">
							<div style="text-align: center" class="margin-left-50">
								<h1 style="font-size: 24px">{{ UserName }}</h1>

								<el-dropdown class="margin-top-sm" @command="handleCommand">
									<span class="el-dropdown-link cursor" style="font-size: 16px; color: #888888">
										{{ Information.Progress | ProgressTypeFilter }}
										<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="{ num: 1 }">新入库</el-dropdown-item>
										<el-dropdown-item :command="{ num: 2 }">沟通/邀约</el-dropdown-item>
										<el-dropdown-item :command="{ num: 3 }">上门咨询</el-dropdown-item>
										<el-dropdown-item :command="{ num: 4 }">异议排除</el-dropdown-item>
										<el-dropdown-item :command="{ num: 5 }">报名入学</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
							<div class="margin-left-lg">
								<!-- <div style="font-size: 15px; color: #464646">
									跟进人：{{ Information.FollowUserName }}
								</div> -->
								<div class="margin-top flex justify-content-between" style="text-align: center">
									<div class="bg-white border-heigth">
										<el-tooltip placement="bottom-start" effect="light">
											<div slot="content">
												{{ Information.UserName }}<br />手机：
												{{ Information.PhoneNumber1 }}
											</div>
											<i class="iconfont icon-dianhua cursor" @click="AddCall(ids)"></i>
										</el-tooltip>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="12" class="border-left">
					<div class="padding-top-sm margin-left-lg">
						<div class="flex flex-wrap-wrap">
							<span>标签</span>
							<div class="margin-left-lg cursor" @click="chooselable(Information.id)">
								<i class="iconfont icon-add"></i>修改
							</div>
						</div>
						<div class="flex flex-wrap-wrap margin-top-sm">
							<div class="bg-blue text-sm clientbuttom margin-right-sm margin-top-xs"
								v-for="item in clientList" :label="item">
								{{ item }}
							</div>
						</div>
					</div>
				</el-col>
			</div>

			<el-tabs v-model="activeName" type="card" class="margin-top">
				<el-tab-pane label="首页" name="first">
					<div class="myClient_client_header clear flex justify-content-between padding-sm">
						<div>
							<div style="font-size: 15px; margin-bottom: 5px">客户星级</div>
							<el-rate v-model="Information.Star" @change="changeStar"></el-rate>
						</div>
						<div>
							<div style="font-size: 12px; margin-bottom: 5px">资料补充</div>
							<div>
								<label class="cursor" @click="activeName = 'second'">补充</label>
							</div>
						</div>
						<div>
							<div style="font-size: 12px; margin-bottom: 5px">上次联系</div>
							<div>{{ lastContact }}</div>
						</div>
						<div>
							<div style="font-size: 12px; margin-bottom: 5px">联系次数</div>
							<div>{{ communicate.length }}</div>
						</div>
						<div>
							<div style="font-size: 12px; margin-bottom: 5px">订单总数</div>
							<!-- <div>0 <label>添加</label></div> -->
						</div>
						<div>
							<div style="font-size: 12px; margin-bottom: 5px">成交单量</div>
							<div>0</div>
						</div>
						<div>
							<div style="font-size: 12px; margin-bottom: 5px">成交总额</div>
							<div>0</div>
						</div>
					</div>
					<div class="margin-tb">
						
						<div style="text-align: center" class="margin-top-xl">
							<el-radio-group v-model="tabPosition" @change="radioChange">
								<el-radio-button label="">所有动态</el-radio-button>
								<el-radio-button label="1">跟进记录</el-radio-button>
								<el-radio-button label="2">沟通交流</el-radio-button>
								<el-radio-button label="3">客户行为</el-radio-button>
								<el-radio-button label="4">销售活动</el-radio-button>
								<el-radio-button label="5">流转记录</el-radio-button>
								<el-radio-button label="6">资料维护</el-radio-button>
							</el-radio-group>
						</div>
						<div class="margin-top-sm margin-left-xl">
							<div class="margin-top-lg">
								<el-timeline style="width: 80%">
									<el-timeline-item :timestamp="item.AddTime | dateTimeFilter" placement="top"
										v-for="(item, index) in DynamicList" :key="index">
										<h4 class="margin-top-sm">{{ item.Event }}</h4>
										<div class="margin-tb-sm">{{ item.Behavior }}</div>
										<el-card>
											<div class="flex justify-content-between"
												v-for="(res, serve) of item.BehaviorContent">
												<div style="width: 70%">
													<div>
														{{ res.name }}<span
															style="padding: 0 5px">:</span>{{ res.value }}
													</div>
												</div>
												<div>{{ res.AddTime | dateTimeFilter }}</div>
											</div>
										</el-card>
									</el-timeline-item>
								</el-timeline>
							</div>
							<div style="text-align: center" v-if="!DynamicList.length">
								<i class="iconfont icon-zanwushuju" style="font-size: 64px"></i>
								<div>暂无数据</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="资料" name="second">
					<h1>基本信息</h1>
					<div class="flex justify-content-between margin-top">
						<border-title titleName="基本信息" />
						<el-button type="success" @click="isDisabled" v-show="Disabled">编辑</el-button>
					</div>
					<div class="margin-top">
						<el-form :label-position="labelPosition" label-width="80px" :model="Information">
							<div class="flex myClient-right-10">
								<el-form-item label="客户全名">
									<el-input v-model="Information.UserName" :disabled="Disabled"></el-input>
								</el-form-item>

								<el-form-item label="客户简称">
									<el-input v-model="Information.Abbreviation" :disabled="Disabled"></el-input>
								</el-form-item>
							</div>
							<div class="myClient-right-10">
								<el-form-item label="客户进展">
									<el-select v-model="Information.Progress" placeholder="请选择" :disabled="Disabled">
										<el-option label="新入库" :value="1"></el-option>
										<el-option label="沟通/邀约" :value="2"></el-option>
										<el-option label="上门咨询" :value="3"></el-option>
										<el-option label="异议排除" :value="4"></el-option>
										<el-option label="报名入学" :value="5"></el-option>
									</el-select>
								</el-form-item>
							</div>
							<div class="myClient-right-10">
								<el-form-item label="就读学校">
									<el-input type="textarea" v-model="Information.School" placeholder="1000个字以内"
										:disabled="Disabled">
									</el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="年级">
									<el-input type="textarea" v-model="Information.Grade" placeholder="1000个字以内"
										:disabled="Disabled">
									</el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="性别">
									<el-radio :label="item.value" v-for="item of genderList" :key="item.value"
										v-model="Information.Sex" :disabled="Disabled">{{ item.label }}</el-radio>
								</el-form-item>
							</div>

							<div class="flex myClient-right-10">
								<el-form-item label="生日">
									<el-date-picker type="date" placeholder="选择日期" v-model="Information.Birthday"
										:disabled="Disabled">
									</el-date-picker>
								</el-form-item>
							</div>
							<div class="flex myClient-right-10">
								<el-form-item label="来源">
									<el-select  v-model="Information.Source" :disabled="Disabled">
										<el-option :label="item.sub_title" v-for="item of sourceList" :key="item.value"
											:value="item.id"></el-option>
									</el-select>
								</el-form-item>
							</div>
							<div class="flex myClient-right-10">
								<el-form-item label="客户分组">
									<el-select v-model="Information.Grouping" placeholder="请选择" :disabled="Disabled">
										<el-option :label="item.GroupName" :value="item.id"
											v-for="(item, index) of datalist_enterprise" :key="index"></el-option>
									</el-select>
								</el-form-item>
							</div>

							<div class="flex myClient-right-10">
								<el-form-item label="微信">
									<el-input v-model="Information.WeChat" :disabled="Disabled"></el-input>
								</el-form-item>

								<el-form-item label="QQ">
									<el-input v-model="Information.QQ" :disabled="Disabled"></el-input>
								</el-form-item>
							</div>

							<div class="flex myClient-right-10">
								<el-form-item label="手机号1">
									<el-input v-model="Information.PhoneNumber1" :disabled="Disabled"></el-input>
								</el-form-item>

								<el-form-item label="手机号2">
									<el-input v-model="Information.PhoneNumber2" :disabled="Disabled"></el-input>
								</el-form-item>
							</div>

							<div class="flex myClient-right-10">
								<el-form-item label="座机">
									<el-input v-model="Information.FixedTelephone" :disabled="Disabled"></el-input>
								</el-form-item>

								<el-form-item label="邮箱">
									<el-input v-model="Information.Mail" :disabled="Disabled"></el-input>
								</el-form-item>
							</div>
							<div class="myClient-right-10">
								<el-form-item label="工作单位">
									<el-input v-model="Information.WorkUnit" :disabled="Disabled"></el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="地址">
									<el-input v-model="Information.Address" :disabled="Disabled"></el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="备注">
									<el-input type="textarea" v-model="Information.Remarks" placeholder="1000个字以内"
										:disabled="Disabled">
									</el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="性格特点">
									<el-input type="textarea" v-model="Information.Character" placeholder="1000个字以内"
										:disabled="Disabled">
									</el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="成长环境">
									<el-input type="textarea" v-model="Information.Environment" placeholder="1000个字以内"
										:disabled="Disabled">
									</el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="特殊需求">
									<el-input type="textarea" v-model="Information.SpecialNeeds" placeholder="1000个字以内"
										:disabled="Disabled">
									</el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="进门成绩">
									<el-input type="textarea" v-model="Information.OriginalResults"
										placeholder="1000个字以内" :disabled="Disabled"></el-input>
								</el-form-item>
							</div>
							<div class="myClient-right-10">
								<el-form-item label="入学测试">
									<el-input type="textarea" v-model="Information.EntranceText" placeholder="1000个字以内"
										:disabled="Disabled"></el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="结课成绩">
									<el-input type="textarea" v-model="Information.FinalGrade" placeholder="1000个字以内"
										:disabled="Disabled"></el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="期末考试成绩">
									<el-input type="textarea" v-model="Information.FinalExam" placeholder="1000个字以内"
										:disabled="Disabled">
									</el-input>
								</el-form-item>
							</div>

							<div class="myClient-right-10">
								<el-form-item label="报名入学时间">
									<el-input type="textarea" v-model="Information.RegistrationTime"
										placeholder="1000个字以内" :disabled="Disabled">
									</el-input>
								</el-form-item>
							</div>
							<div class="flex justify-content-flexEnd" v-if="Disabled == false">
								<el-button type="primary" @click="submit">确定</el-button>
								<el-button @click="cancel">取消</el-button>
							</div>
						</el-form>
					</div>
				</el-tab-pane>
				<el-tab-pane label="文件" name="third">
					<div class="flex flex-wrap-wrap">
						<el-upload :action="uploadActionUrl" :data="{ source: 'customer' }"
							:headers="{Authorization:token}" :show-file-list="false" :on-success="handleAvatarSuccess"
							:on-progress="handleProgress">
							<el-button type="primary" size="mini" @click="addFiler(1)">上传文件</el-button>
						</el-upload>
						<el-button  style="margin-left: 15px;"  @click="editHandle(2, Information.id)" size="mini"  v-if="isShow">新建文件夹
						</el-button>
					</div>
					<div class="margin-top flex flex-wrap-wrap">
						<span>客户文件</span>
						<div v-if="!isShow" class="flex flex-wrap-wrap">
							<h2 class="margin-left-sm">/ 跟进记录附件</h2>
							<div class="margin-left-sm text-gray cursor" @click="changeIsShow">
								返回上一层
							</div>
						</div>
					</div>
				
					<el-table :data="folderList" style="width: 100%;margin-top: 10px;" v-if="isShow" v-loading="folderLoading">
						<el-table-column label="名称">
							<template slot-scope="scope">
								<div @click="rowChange(scope.row)" v-if="scope.row.FolderName">
									<i class="iconfont icon-wenjian3 line-orange" ></i>
									<span style="margin-left: 10px" class="cursor">{{ scope.row.FolderName }}</span>
								</div>
								<div v-else>
									<a  target="_blank" :href="scope.row.FileUrl | previewImage ">
									<i class="iconfont icon-wenjian line-orange"></i>
									<span style="margin-left: 10px" class="cursor">
										{{scope.row.FileName}}</span>
										</a>
								</div>
							</template>
						</el-table-column>
				
						
						<el-table-column label="创建时间" sortable width="200" prop="time">
							<template slot-scope="scope">
								<span style="margin-left: 10px">{{ scope.row.AddTime | dateTimeFilter}}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="220" align="center">
							<template slot-scope="{ row, $index }">
								<div class="flex justify-content-around">
									<el-upload :action="uploadActionUrl" :data="{ source: 'customer' }"
										:headers="{Authorization:token}" :show-file-list="false"
										:on-success="handleAvatarSuccess" :on-progress="handleProgress">
										<el-button type="primary" size="mini" @click="addFiler(row)" v-if="row.FolderName">上传文件</el-button>
									</el-upload>
									<span class="text-blue cursor" @click="editHandle(2, row)" v-if="row.FolderName">编辑</span>
									<span class="text-red cursor" @click="detail(row, $index)">删除</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				
					<FileLists v-if="!isShow" :folderId="FolderId" style="margin-top: 10px;"></FileLists>
				
					<div class="text-grey margin-top-lg">
						注：可数被删除后，文件将被同步删除
					</div>
				</el-tab-pane>
				
				
			</el-tabs>
<addfile :Visible="editVisible && editType === 2" :message="fileMessage" @closepop="closeEditPop"></addfile>
		</div>
	</el-dialog>
</template>

<script>
	
	import {
		formatTime
	} from "@/utils/util.js";
import addfile from "@/views/myClient/components/addFile.vue";
import FileLists from "@/views/myClient/components/fileLists.vue";
import {
		getToken
	} from '@/utils/auth'
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},
			customersID: {
				type: Number,
				default: 0,
			},
		},
		components:{
			addfile,
			FileLists
		},
		data() {
			return {
				token: getToken(),
				UserName:'',
				loading: true,
				loadings: true,
				isShow: true,
				folderLoading: false,
				fileMessage: {
					uerid: 0,
					detail: {},
				},
				FolderId: {},
				ids: 0,
				Progress: "",
				checkboxGroup1: [],
				Disabled: true,
				labelPosition: "right",
				activeName: "first",
				dialogVisible: false,
				editType: 1,
				fileList: [],
				folderList: [],
				tabPosition: "",
				Information: {},
				DynamicList: [], //客户动态
				communicate: [], //客户动态——沟通交流
				lastContact: "", //上次联系时间
				FolderMessage: {},
				uploadFiles: {
					CustomerUserId: 0,
					FolderId: "",
					FileName: "",
					FileSize: 0,
					FileUrl: "",
				},
				clientList: [],
				datalist_enterprise: [],
				listQuery: {
					PageIndex: 1,
					PageSize: 20,
					Sort: [{
						Field: "id",
						Type: 0,
					}, ],
				},
				DynamicListQuery: {
					CutomerUserId: 0,
					Dynamic: "",
					PageIndex: 1,
					PageSize: 20,
					Sort: [{
						Field: "id",
						Type: 1,
					}, ],
				},
				PlanStatusQuery: {
					CutomerUserId: 0,
					sale_PlanStatus: "1",
					PageIndex: 1,
					PageSize: 20,
					Sort: [{
						Field: "id",
						Type: 1,
					}, ],
				}, 
				sourceList: [], //来源列表
				listQuery1: {
					HasRole: false,
					module: '',
					TotalCount: 0,
					PageIndex: 1,
					PageSize: 15
				},
			};
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
				console.log(this.customersID)
				this.ids = this.customersID;
				this.PlanStatusQuery.CutomerUserId = this.ids;
				this.Information = {};
				this.getViewCustomer(this.ids);
				this.getSelectMyFolder(this.ids);
				this.getDynamicList();
				this.getDynamicDynamic();
				this.getSourceList();
				this.API.customerSelectMyGroup(this.listQuery).then((res) => {
					this.datalist_enterprise = res.data.rows;
				});
			},
			
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit("closepop");
			},
			isDisabled() {
				this.Disabled = !this.Disabled;
			},
			closeEditPop() {
				this.editVisible = false;
				this.getSelectMyFolder(this.ids);
			},
			closePop(val) {
				this.editVisible = false;
				this.uploadFiles.CustomerUserId = val.CustomerUserId;
				this.uploadFiles.FolderId = val.FolderId;
				this.API.customerAddFileToFolder(this.uploadFiles).then((res) => {
					this.$message.success("上传成功");
				});
			},
			//获取客户来源列表
			getSourceList() {
				this.listQuery1.module = 'SaleOrigin';
				this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
					this.sourceList = res.data.rows;
					this.$forceUpdate()
				});
			},
			editHandle(type, id) {
				this.editVisible = true;
				this.editType = type;
				if (typeof(id) === "number") {
					this.fileMessage.uerid = id;
					this.fileMessage.title = "新建文件夹"
				} else if (typeof(id) === "object") {
					this.fileMessage.title = "编辑文件夹"
					this.fileMessage.detail = id;
				}
			},
			//修改星级
			changeStar(val) {
				this.$confirm("确定修改该客户的星级吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.Information.Star = val;
						this.API.postEditCustomer(this.Information).then((res) => {
							if (res.code == -1) {
								this.$message.eval(res.message);
							} else if (res.code == 200) {
								this.$message.success("编辑成功");
							}
						});
					})
					.catch(() => {});
				console.log(val);
			},
			AddCall(id) {
				this.$confirm("确定将该用户加入到待拨打电话界面?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.API.callAddCallList(id).then((res) => {
							if (res.code == -1) {
								this.$message.eval(res.message);
							} else if (res.code == 200) {
								this.$message.success("成功加入待拨打列表");
							}
						});
					})
					.catch(() => {});
			},
			//客户的资料
			getViewCustomer() {
				this.API.customerView_Customer(this.ids).then((res) => {
					if (res.Label == null) {
						this.clientList = []
					} else {
						this.clientList = res.Label.split(",");
					}
					this.Information = res;
					this.UserName=this.Information.UserName
					this.loadings = false;
				});
			},
			//客户的文件夹
			getSelectMyFolder() {
				this.API.customerSelectMyFolder(this.ids).then((res) => {
					let data = {
						FolderId:0,
						CustomerId:this.ids
					}
					let folderList = res.data.rows;
					//根目录下的文件
					this.API.customerSelectMyFolderFile(data).then(res => {
						this.folderList = folderList.concat(res.data.rows)
					})
				});
			},

			radioChange(val) {
				this.tabPosition = val;
				this.DynamicListQuery.Dynamic = val;
				this.getDynamicList();
			},
			//获取客户动态
			getDynamicList() {
				this.DynamicListQuery.CutomerUserId = this.ids;
				this.API.customerSelectCutomerDynamicList(this.DynamicListQuery).then(
					(res) => {
						this.DynamicList = res.data.rows;
						this.DynamicList.map((item) => {
							item.BehaviorContent = JSON.parse(item.BehaviorContent);
						});
					}
				);
			},
			getDynamicDynamic() {
				this.API.customerSelectCutomerDynamicList(this.DynamicListQuery).then(
					(res) => {
						this.communicate = res.data.rows;

						if (this.communicate[0]) {
							this.lastContact = this.communicate[0].AddTime;
						} else {
							this.lastContact = "暂无记录";
						}
					}
				);
			},
			handleCommand(command) {
				this.Information.Progress = command.num;
				this.submit();
			},
			addFiler(val) {
				if (val != 1) {
					this.FolderMessage = val
				}
			},
			//客户编辑资料
			submit() {
				this.API.postEditCustomer(this.Information).then((res) => {
					if (res.code == 200) {
						this.$message.success("编辑成功");
						this.Disabled = true;
						this.getDynamicList()
					}
				});
			},
			//删除文件夹
			detail(val, index) {
				if (val.FolderName) {
					this.$confirm(
							"此操作将永久删除该文件夹以及下面的资料, 是否继续?",
							"提示", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "warning",
							}
						)
						.then(() => {
							this.folderList.splice(index, 1);
							this.API.customerDeleteFolder(val.id).then((res) => {
								if (res.code == 200) {
									this.$message.success("文件夹删除成功");
								}
							});
						})
						.catch(() => {
							this.$message({
								type: "info",
								message: "已取消删除",
							});
						});
				} else {
					this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.folderList.splice(index, 1)
						this.API.customerDeleteFileToFolder(val.id).then(res => {
							if (res.code == 200) {
								this.$message.success("文件删除成功")
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},
			changeIsShow() {
				this.FolderMessage = {}
				this.isShow = !this.isShow
			},
			
			rowChange(row) {
				console.log(row)
				this.FolderMessage = row
				this.isShow = false;
				this.FolderId ={
					 folderId:row.id,
					 CustomerId:this.ids
				};
			},
			cancel() {
				this.Disabled = true;
			},
			handleProgress(file) {
				this.folderLoading = true
			},
			handleAvatarSuccess(res, file) {
				console.log(this.FolderMessage)
				this.folderLoading = false
				if (this.FolderMessage.id) {
					this.uploadFiles.FolderId = this.FolderMessage.id
				} else {
					this.uploadFiles.FolderId = 0
				}
				this.uploadFiles.CustomerUserId = this.ids
				this.uploadFiles.FileName = res.data.Name
				this.uploadFiles.FileSize = res.data.Size
				this.uploadFiles.FileUrl = res.data.src
				this.API.customerAddFileToFolder(this.uploadFiles).then(res => {
					this.$message.success("上传成功");
				})
				this.getSelectMyFolder()
				if(this.FolderMessage.id){
					this.isShow = true;
					this.FolderId.folderId = this.FolderMessage.id;
					this.$nextTick(() => (this.isShow = false))
				}
				
			},
			
			//选择客户标签
			chooselable(Userid) {
				this.$Dialog.ChooseCustomerlabel({
						Userid: Userid,
						num: 2,
					})
					.then((res) => {
						console.log(res)
						this.clientList = res.split(",");
						this.clientList.push(this.clientList.pop());
					});
			},
		},
	};
</script>

<style scoped>
	>>>.el-form-item__label {
		line-height: 26px !important;
	}

	.background-colorF {
		background-color: #f7f7f7;
	}

	.icon {
		width: 50px;
		height: 50px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.border-radius-box {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		line-height: 40px;
		text-align: center;
		background-color: #ffffff;
		border: 1px solid #f0f0f0;
	}

	.clientbuttom {
		width: 85px;
		text-align: center;
		height: 30px;
		line-height: 30px;
		border-radius: 4px;
	}

	.border-heigth {
		border-radius: 50%;
		width: 25px;
		height: 25px;
		line-height: 25px;
	}
</style>
