<template>
	<div>
		<el-table :data="fileList" style="width: 100%" v-loading="loading">
			<el-table-column label="名称">
				<template slot-scope="scope">
					<a target="_blank" :href="scope.row.FileUrl | previewImage ">
						<i class="iconfont icon-wenjian line-orange"></i>
						<span style="margin-left: 10px">{{ scope.row.FileName }}</span>
					</a>
				</template>
			</el-table-column>
			<el-table-column label="大小" width="120">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{scope.row.FileSize | ByteChangeFilter}}</span>
				</template>
			</el-table-column>
			<el-table-column label="创建时间" sortable width="180" prop="time">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.AddTime | dateTimeFilter}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="120">
				<template slot-scope="{row,$index}">
					<div class="flex justify-content-around">
						<span class="text-red cursor" @click="detail(row.id,$index)">删除</span>
					</div>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		props: ['folderId'],
		data() {
			return {
				loading: true,
				fileList: []
			}
		},
		created() {
			console.log('sdg')
			this.getSelectMyFolderFile(this.folderId)
		},
		methods: {
			getSelectMyFolderFile(val) {
				let data = {
					FolderId:val.folderId,
					CustomerId:val.CustomerId
				}
				this.API.customerSelectMyFolderFile(data).then(res => {
					this.loading = false
					this.fileList = res.data.rows
				})
			},
			detail(ids, index) {
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.fileList.splice(index, 1)
					this.API.customerDeleteFileToFolder(ids).then(res => {
						if (res.code == 200) {
							this.$message.success("文件删除成功")
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});

			}
		}
	}
</script>

<style>
</style>
